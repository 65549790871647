/* src/App.css */
body {
  font-family: 'Comic Neue', cursive;
  background-color: #f0f8ff;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
  background-color: #ffebcd;
  border-bottom: 3px solid #deb887;
  margin: 0;
}

nav ul li {
  margin: 10px 0;
}

nav ul li a {
  text-decoration: none;
  color: #d2691e;
  font-size: 1.2em;
}

nav ul li a:hover {
  color: #ff4500;
}

h1 {
  color: #4682b4;
  font-size: 1.6em; /* Set a specific font size for h1 */
}

h2 {
  color: #4682b4;
  font-size: 1.2em; /* Set a specific font size for h2 */
}

h3 {
  color: #4682b4;
  font-size: 1.75em; /* Set a specific font size for h3 */
}

button {
  background-color: #ff7f50;
  border: none;
  border-radius: 10px;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 1em;
  margin-top: 10px;
}

button:hover {
  background-color: #ff6347;
}

ul {
  padding: 0;
}

ul li {
  list-style: none;
  margin: 10px 0;
}

p {
  font-size: 1.1em;
  color: #2f4f4f;
}

.container {
  padding: 20px;
  max-width: 800px;
  margin: 20px auto; 
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* Remove flex-grow if you don't want it to stretch */
  /* flex-grow: 1; */
  /* Remove any height related styles */
  height: auto; 
  box-sizing: border-box; /* Ensure padding and border are included in the width */
}


.info {
  background-color: #e0ffff;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
  font-size: 1em;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer-wrapper {
  width: 100%;
  margin-top: auto; /* Push the footer to the bottom */
}
