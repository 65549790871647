/* src/components/PuzzleSolving.css */
.puzzle-container {
    text-align: center;
  }
  
  .tile-grid {
    display: flex;
    justify-content: center;
    gap: 5px; /* Adjust the gap to be smaller */
    margin-top: 20px;
  }
  
  .tile {
    width: 24px; /* Adjust the width to make the tiles smaller */
    height: 24px; /* Adjust the height to make the tiles smaller */
    background-color: #f0f0f0;
    border: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .tile.correct {
    background-color: green;
  }
  
  .dog-ascii {
    display: none;
    margin-top: 20px;
    white-space: pre;
    font-family: monospace;
  }
  
  .dog-ascii.reveal {
    display: block;
  }
  