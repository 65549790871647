/* src/components/Footer.css */
.footer {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    color: #666;
  }
  
  .footer span {
    display: block;
  }
  