/* src/Home.css */
.home {
    text-align: center;
    padding: 20px;
  }
  
  .bento-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .bento-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #ffebcd; /* Ensure background color is set */
    border-radius: 15px;
    padding: 20px;
    text-decoration: none;
    color: #2f4f4f;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    height: 100%;
  }
  
  .bento-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  
  .bento-item h2 {
    color: #4682b4;
  }
  
  .bento-item p {
    color: #2f4f4f;
    font-size: 1em;
    margin-top: auto;
  }
  