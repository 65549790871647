/* src/ResourceManager.css */
.resource-manager {
  padding: 0px;
  max-width: 800px;
  margin: 0px auto;
  /* background-color: #ffffff; */
  /* border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  text-align: center;
}

.resource-manager h2 {
  color: #4682b4;
}

.resource-manager p {
  font-size: 1.1em;
  color: #2f4f4f;
}

.resource-visualization {
  margin-top: 20px;
}

.gems {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.gem {
  font-size: 2em;
  cursor: pointer;
  margin: 5px;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.gem:hover {
  animation: flicker 0.2s infinite alternate, rotate 0.5s ease;
}

@keyframes flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.task-container {
  margin-top: 20px;
  padding: 15px;
  background-color: #e0ffff;
  border-radius: 10px;
}

.feedback {
  margin-top: 20px;
  font-size: 1.1em;
  color: #32cd32;
}
